<!-- 荣誉处分详情 -->
<template>
  <div class="honor-punish-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '荣誉详情',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="content" v-if="detail">
      <div class="title">{{ detail.title }}</div>
      <div class="desc">&nbsp;&nbsp;&nbsp;&nbsp;{{ detail.remark }}</div>
      <div class="images" v-for="(file, f) in imgArr" :key="f">
        <van-image width="100%" height="5rem" fit="contain" :src="file.url" />
      </div>
      <p
        class="desc link right"
        @click="openUrl(item.url)"
        v-for="(item, i) in otherArr"
        :key="i"
      >
        {{ item.name }}
      </p>
      <p class="desc right">{{ detail.updateTime }}</p>
    </div>
    <van-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import { getInfo } from "@/api/honorPunish/index.js";
export default {
  data() {
    return {
      detail: null,
    };
  },

  components: { TopWrapper },

  computed: {
    id() {
      const { id } = this.$route.params;
      return id;
    },
    imgArr() {
      if (!this.detail) return [];
      if (!this.detail.files) return [];
      let imgs = [];
      for (let e of this.detail.files) {
        const suffix = `(jpg|png|jpeg)`;
        const regular = new RegExp(`.*\.${suffix}`);
        if (regular.test(e.name)) {
          imgs.push(e);
        }
      }

      return imgs;
    },
    otherArr() {
      if (!this.detail) return [];
      if (!this.detail.files) return [];
      let others = [];
      for (let e of this.detail.files) {
        const suffix = `(jpg|png|jpeg)`;
        const regular = new RegExp(`.*\.${suffix}`);
        if (!regular.test(e.name)) {
          others.push(e);
        }
      }
      return others;
    },
  },

  mounted() {
    this.getDetail();
  },

  methods: {
    getDetail() {
      getInfo({ code: this.id }).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.detail = data;
          }
        }
      });
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.honor-punish-page {
  width: 100%;
  min-height: 100%;
  background: #fafafa;
  margin-top: 0.3rem;
  .content {
    padding: 0.3rem;
    background: #fff;
    min-height: calc(100vh - 0.3rem);
    .title {
      width: 100%;
      font-size: 0.34rem;
      font-weight: 500;
      color: #000000;
      line-height: 0.48rem;
      margin-bottom: 0.4rem;
      text-align: center;
    }
    .desc {
      font-size: 0.28rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.48rem;
      margin-bottom: 0.3rem;
    }
    .link {
      color: #0094ee;
    }
    .images {
      margin-bottom: 0.2rem;
    }
    .right {
      width: 100%;
      text-align: right;
    }
  }
}
</style>
